import React from "react";
import "../about/about.css";
import AOS from "aos";
import aboutimg from "../../../../assets/mo-abdalmagid/aboutim.png";
import aboutimg2 from "../../../../assets/mo-abdalmagid/banner3.png";
import exam from "../../../../assets/mo-abdalmagid/exams.png";
import quizs from "../../../../assets/mo-abdalmagid/quizs.png";
import bank from "../../../../assets/mo-abdalmagid/bank.png";
import { IoIosCheckmarkCircle } from "react-icons/io";
export const About = () => {
  AOS.init();
  return (
    <div className="about">
      <div  data-aos="fade-left" data-aos-duration="1000" className="about-left">
        <img src="" alt="" srcSet={aboutimg} />
        <img className="banner" src="" alt="" srcSet={aboutimg2} />
      </div>
      <div  data-aos="fade-right" data-aos-duration="1000" className="about-right">
        <h1>أقـوي منصة تـعليمية في <span className="marker">مـادة الفيزياء</span></h1>
        <p>
        اهلا بيك في منصتنا التعليمية, احنا هنا عشان نساعدك توصل لكل المعلومات الي محتاجها بسهوله ،انضم لينا و مش بس هتبقي بتتعلم عن الفيزياء بالعكس هتبقي منبهر و مستمتع وانت بستكتشف فيزياء كوكبنا 🌍❤️
        </p>
        <div className="advent">

        <div className="prp onee">
          <img className="imm object-fit-contain" src="" alt="" srcSet={exam} />
          <h4>اختبارات دورية يحددها الاستاذ بهدف المراجعة الدورية .</h4>
        </div>
        <div className="prp">
        <img className="imm object-fit-contain" src="" alt="" srcSet={quizs} />
        <h4>مراجعات وامتحانات </h4>
        </div>
        <div className="prp">
        <img className="imm object-fit-contain" src="" alt="" srcSet={bank} />
          <h4>توفير اكبر قدر من الأسئلة</h4>
        </div>
      </div>
        </div>
    </div>
  );
};

import React from "react";
import lightLogo from "../assets/mo-abdalmagid/ho-img.png";
import companyLogo from "../assets/company-logo.png";

export default function Footer() {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="contact-section">
                        <div className="footer-logo">
                            <img className="w-100 h-100" alt="" src={lightLogo} />
                        </div>
                        <h4 className="text-center">تواصل معنا عبر</h4>
                        <div className="row py-2 w-100 ">
                        </div>
                        <div className="footer-social-media py-1">
                            <a
                                href="https://www.facebook.com/people/%D8%A7%D9%84%D8%B9%D8%B1%D8%A8%D9%89-%D8%B4%D8%AA%D8%A7%D9%8A%D9%86-Elaraby-Shtein/100070156758552/?mibextid=ZbWKwL"
                                target="_blank"
                            >
                                <i className="fa-brands fa-facebook"></i>
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCh-LOaDAuRpLZCsJUGZxmpw?app=desktop&fbclid=IwAR2E0XNMtKiFIeDAEbXlRyPlg_Hr_7ZXW6UQ6Zfs8JLtDOYcGndYRQKGz6g"
                                target="_blank"
                            >
                                <i className="fa-brands fa-youtube"></i>
                            </a>
                            <a
                                href="https://www.tiktok.com/@alaraby_shtain?_t=8mxMA3w13wr&_r=1"
                                target="_blank"
                            >
                                <i className="fa-brands fa-tiktok"></i>
                            </a>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="copyright-logo">
                            <img className="w-100 h-100" src={companyLogo} alt="" />
                        </div>
                        <p>حقوق النشر © 2024 لشركة Easy Tech . كل الحقوق محفوظة.</p>
                    </div>
                </div>
            </footer>
        </>
    );
}

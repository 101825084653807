import React, { useEffect, useRef } from "react";
import "../header/Header.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { LiaAtomSolid } from "react-icons/lia";
import { PiWavesBold } from "react-icons/pi";
import { GiEarthAfricaEurope } from "react-icons/gi";
import { GiHeatHaze } from "react-icons/gi";
import an_img from '../../../../assets/mo-abdalmagid/herobanner__1.png'
import an_img2 from '../../../../assets/mo-abdalmagid/herobanner__2.png'
import an_img3 from '../../../../assets/mo-abdalmagid/herobanner__3.png'
import an_img4 from '../../../../assets/mo-abdalmagid/herobanner__4.png'
import homeimg from "../../../../assets/mo-abdalmagid/ho-img.png";
import homeimg2 from "../../../../assets/mo-abdalmagid/about-shape.png";
export const Header = () => {


  const header = useRef()

  const top = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {

    let scrollHandler = () => {
      if (window.scrollY > 0) {
        header.current.style.display = "flex";
      }
      else {
        header.current.style.display = "none";
      }
    }
    window.addEventListener('scroll',scrollHandler)

    AOS.init();

    return () => {
      window.removeEventListener('scroll' , scrollHandler)
    }

  }, [])

  return (
    <>
      <section className="home">
        <div className="atoms">
          <LiaAtomSolid className="atom one" />
          <PiWavesBold className="atom two" />
          <GiEarthAfricaEurope className="atom three" />
          <GiHeatHaze className="atom four" />
        </div>
        <div data-aos="fade-right" data-aos-duration="1000" className="home-left">
          <img alt="home img" srcSet={homeimg} />
        </div>
        <div data-aos="fade-left" data-aos-duration="1000" className="home-right">
          <h1> إبدأ رحلتك فى عالم الفيزياء مع منصة  <span>  مستر محمد العربي </span></h1>
          <p>
          أقوى منصة تعليمية لمادة الفيزياء لطلبة الثانويه العامه والأزهرية
          </p>
          <img alt="home img" srcSet={homeimg2} />
        </div>
        <div className="buttom" >
          <button onClick={top} class="button" ref={header}>
            <svg className="svgIcon" viewBox="0 0 384 512">
              <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"></path>
            </svg>
          </button>
        </div>
      </section>
    </>
  );
};

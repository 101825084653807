import React, { useEffect } from "react";
import "../Advert/advert.css"
import { IoIosCheckmarkCircle } from "react-icons/io";
import { PiLampPendantFill } from "react-icons/pi";
import { IoBarChart } from "react-icons/io5";
import { FaBookReader } from "react-icons/fa";
import AOS from "aos";
import advimg from'../../../../assets/mo-abdalmagid/Group 157.png'
export const Advert = () => {
  useEffect(()=>{
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,  
    });  
    },)
    return (
    <div className="advert">
      <div  data-aos="fade-left" data-aos-duration="1000" className="advert-left">
        <h1>منهج تعليمي متميز و اختبارات</h1>
        <p className="p-border">نهدف إلى تقديم تعليم ذو جودة عالية وشامل يغطي جميع جوانب التعلم. محتوى المنصة مبني على أسس علمية ومعايير دولية لضمان توفير أفضل تجربة تعليمية للطلاب.</p>
        <img  alt="" srcSet={advimg} />
      </div>
      <div  data-aos="fade-right" data-aos-duration="1000" className="advert-right">
        <div className="adv">
        <IoBarChart className="dev-icon" />
        <p>منصة فيها كل اللي يحتاجه الطالب في رحلته</p>
        </div>
        <div className="adv">
        <PiLampPendantFill className="dev-icon" />
        <p>امتحانات و تركات في المنهج في منصة ال..</p>
        </div>
        <div className="adv">
        <FaBookReader className="dev-icon" />
        <p>مش هنسيب حاجه في المنهج غير و هنوضحهالك و نفهمالك</p>
        </div>
      </div>
    </div>
  );
};

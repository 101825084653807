import React, { useEffect, useState } from "react";
import "../properties/properties.css";
import { FaCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";
export const Properties = () => {
  return (
    <>
      <div className="prop">
        <div className="prop-left">
          <div className="pr">
            <FaCheck  className="pr-icon" />
            <h2>شرح كامل للدروس</h2>
          </div>
          <div className="pr">
            <FaCheck className="pr-icon" />
            <h2>امتحانات علي كل درس</h2>
          </div>
          <div className="pr">
            <FaCheck className="pr-icon" />
            <h2>امتحانات دورية</h2>
          </div>
        </div>
        <div className="prop-left">
          <div className="pr">
            <FaCheck className="pr-icon" />
            <h2>مناقشات عامة و متابعة مع المدرس </h2>
          </div>
          <div className="pr">
            <FaCheck  className="pr-icon"/>
            <h2>اختبارات يختارها الطالب بنفسه</h2>
          </div>
          <div className="pr">
            <FaCheck  className="pr-icon"/>
            <h2>بنك اسألة كامل و شامل للطالب</h2>
          </div>
        </div>
        <Link className="btnn" to="/auth" >إنضم إلينا</Link>
      </div>
    </>
  );
};

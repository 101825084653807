import React, { useContext, useState } from 'react'
import defaultUserImage from '../../assets/auth/userLoginIcon.svg'
import StepOne from './RegisterSteps/StepOne';
import StepTow from './RegisterSteps/StepTow';
import ThirdStep from './RegisterSteps/ThirdStep';
import StepFour from './RegisterSteps/StepFour';
import axios from 'axios';
import config from '../../config';
import { auth } from "../../Context/AuthStore"
import Validator from '../../Validator';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function Register(props) {
    const { setUserAuth } = useContext(auth)
    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [currentStep, setCurrentStep] = useState(1)
    const { setHaveError, showSwal, createPopupErrors } = useOutletContext()

    const navigate = useNavigate()


    const [dataToSend, setDataToSend] = useState({
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
        name: '',
        lastName: '',
        parent_phone: '',
        type_education: '',
        year: '',
        place: '2',
        governorate: '',
    })

    let incresSteps = () => {
        let validation = new Validator
        let st = currentStep;


        if (st === 1) {
            setErrors([])
            validation.setKey('name').setValue(dataToSend.name).required().is_arabic().words_count(4)
            if (validation.haveError) {
                setErrors(validation.getErrors())
                return;
            }
        } else if (st === 2) {
            setErrors([])
            validation.setKey('username').setValue(dataToSend.username).required().len(11).number().notMatch(dataToSend.parent_phone)
            validation.setKey('parent_phone').setValue(dataToSend.parent_phone).required().len(11).number().notMatch(dataToSend.username)
            if (validation.haveError) {
                setErrors(validation.getErrors())
                return;
            }
        } else if (st === 3) {
            setErrors([])
            validation.setKey('governorate').setValue(dataToSend.governorate).required()
            validation.setKey('type_education').setValue(dataToSend.type_education).required()
            validation.setKey('year').setValue(dataToSend.year).required()
            if (validation.haveError) {

                setErrors(validation.getErrors())
                return;
            }
        }
        // add to next
        if (st !== 4) {
            st += 1;
        }
        setCurrentStep(st)
    }

    let dicressSteps = () => {
        let st = currentStep;
        if (st !== 1) {
            st -= 1;
        }
        setCurrentStep(st)
    }

    let updateUserData = (e) => {
        let data = { ...dataToSend }
        data[e.target.name] = e.target.value
        setDataToSend(data);
    }

    let clearInputData = (inputName) => {
        let data = { ...dataToSend }
        data[inputName] = ''
        setDataToSend(data);
    }

    const sendData = () => {
        let data = { ...dataToSend }
        data.name += ` ${data.lastName}`
        axios.post(`${config.basURL}student/student-sign-up`, data, {
            headers: {
                'Authorization': config.ApiKey
            }
        }).then((res) => {

            if (res.data.user_errors) {
                console.log(res.data)
                setErrors({username: ['انت مشترك فعلا في المنصه بالرقم ده']});
                setHaveError(true)
                setIsLoading(false)
                Swal.fire({
                    text: ` انت مشترك فعلا في المنصه برقم ${ data.username } `, 
                    icon: 'warning' ,
                })
                createPopupErrors({username: 'انت مشترك فعلا في المنصه بالرقم ده'})
                // showSwal()
            } else {
                setUserAuth(res.data.token)
                localStorage.setItem('userToken', res.data.token);
                setErrors({});
                Swal.fire({
                    titleText: 'اهلاك بيك بالمنصه \n انت سجلت بنجاح',
                    icon: 'success'
                })
            }

        }).catch((err) => console.log(err))

    }

    const validation = () => {
        const validate = new Validator;
        validate.setKey('name').setValue(dataToSend.name).required().is_arabic().words_count(4);
        validate.setKey('password').setValue(dataToSend.password).required();
        validate.setKey('password_confirmation').setValue(dataToSend.password_confirmation).required().confirm(dataToSend.password);
        validate.setKey('place').setValue(dataToSend.place).required();
        validate.setKey('type_eduction').setValue(dataToSend.type_education).required();
        validate.setKey('year').setValue(dataToSend.year).required();
        validate.setKey('governorate').setValue(dataToSend.governorate).required();
        validate.setKey('username').setValue(dataToSend.username).required().len(11).notMatch(dataToSend.parent_phone).number();
        validate.setKey('parent_phone').setValue(dataToSend.parent_phone).required().notMatch(dataToSend.username).len(11).number();
        return validate;
    }

    const submitRegister = (e) => {

        e.preventDefault();
        const valid = validation();
        setIsLoading(true)

        if (valid.haveError) {

            setErrors(valid.getErrors())
            setHaveError(true)
            setIsLoading(false)
            createPopupErrors(valid.getErrors())
            showSwal()
        } else {
            setErrors({});
            sendData()
        }
    }

    return <>
        <form className='register-section' encType='multipart/form-data' onSubmit={submitRegister}>
            <div className='container p-4'>
                <div className='steps-bar'>
                    <div className={`step ${currentStep > 1 ? 'completed' : ''} `}></div>
                    <div className={`step ${currentStep > 2 ? 'completed' : ''}`}></div>
                    <div className={`step ${currentStep > 3 ? 'completed' : ''}`}></div>
                    <div className={`step ${currentStep === 4 ? 'completed' : ''}`}></div>
                </div>
                <h5 className='text-center py-4'>
                    انشاء حساب جديد
                </h5>
                {/* user image */}
                <div className='upload-user-image mb-3'>
                    <label className='image' htmlFor='user-image'>
                        <img src={defaultUserImage} alt='default user image' className='w-100 h-100' />
                    </label>
                </div>
                <StepOne show={currentStep === 1 ? true : false} updateUserData={updateUserData} clearInputData={clearInputData} registerData={dataToSend} errors={errors} />
                <StepTow show={currentStep === 2 ? true : false} updateUserData={updateUserData} clearInputData={clearInputData} registerData={dataToSend} errors={errors} />
                <ThirdStep show={currentStep === 3 ? true : false} updateUserData={updateUserData} clearInputData={clearInputData} registerData={dataToSend} errors={errors} />
                <StepFour show={currentStep === 4 ? true : false} updateUserData={updateUserData} clearInputData={clearInputData} registerData={dataToSend} errors={errors} />
                <div className='next-prev-step py-3'>
                    {
                        currentStep !== 4 ? <div onClick={incresSteps} className='next-step'>
                            التالي
                        </div> : <button type='submit' className='next-step register-btn'>
                            {
                                isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'انشاء الحساب'
                            }
                        </button>
                    }
                    <div onClick={dicressSteps} className='prev-step'>
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                </div>
            </div>
        </form>
    </>
}
